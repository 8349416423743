.snackbar-cart {
  position: fixed;
  left: 81%;
  transform: translate(-50%, -50%);
  width: 352px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-radius: 12px;
  z-index: 1000;
}

.snackbar-cart-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

@media only screen and (max-width: 480px) {
  .snackbar-cart {
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .snackbar-cart {
    left: 75%;
  }
}
