:root {
  --primary-1st-color: #0a4d3c;
  --primary-2nd-color: #fcb537;
  --primary-3rd-color: #8d280c;
  --primary-4th-color: #b24629;
  --primary-5th-color: #f1ede7;

  --neutral-1st-color: #070707;
  --neutral-2nd-color: #5a5a5a;
  --neutral-3rd-color: #cacaca;
  --neutral-4th-color: #f4f4f4;
  --neutral-5th-color: #ffffff;

  --semantic-error: #cb3a31;
  --semantic-warning: #ef8943;
  --semantic-success: #43936c;
  --semantic-info: #27a0e3;

  --b-rad-root: 12px;
  --b-rad-table: 8px;
  --b-shadow-root: 0px 1px 6px #d7d7d8;
}

/* ? MAIN WRAPPER SECTION */
.adm-transaction-detail-main-wrap {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1.5rem;
  row-gap: 1.5rem;
}

/* ? BREADCRUMB WRAP & CONTENTS SECTION */
.adm-transaction-detail-breadcrumb-wrap {
  align-items: center;
  display: flex;
  margin-bottom: calc(30px - 1.5rem);
}

.adm-transaction-detail-breadcrumb-wrap li {
  /* Utk centering typography pd breadcrumb, karena lainnya pake Link dari react router dom */
  align-items: center;
  display: flex;
}

/* ? HEADER WRAP & CONTENTS SECTION */
.adm-transaction-detail-header-wrap {
  column-gap: 2rem;
  display: flex;
  justify-content: space-between;
}

.adm-transaction-detail-header-wrap > h5 {
  font-weight: 600;
}

.adm-transaction-detail-header-left {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.detailTrx-header-left-notice {
  align-items: center;
  background: var(--neutral-5th-color);
  border-radius: var(--b-rad-table);
  column-gap: 0.5rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;

  box-shadow: var(--b-shadow-root);
  -webkit-box-shadow: var(--b-shadow-root);
  -moz-box-shadow: var(--b-shadow-root);
}

.detailTrx-header-left-notice > h6 {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
}

/* * TRANSACTION STATUS WRAP & CONTENTS ON HEADER SECTION */
.adm-transaction-detail-status {
  display: flex;
  flex-direction: column;
  min-width: 10%;
  width: fit-content;

  box-shadow: var(--b-shadow-root);
  -webkit-box-shadow: var(--b-shadow-root);
  -moz-box-shadow: var(--b-shadow-root);
}

.adm-transaction-detail-status h6 {
  margin: 0;
}

.transaction-detail-status-top {
  align-items: center;
  background: var(--neutral-5th-color);
  border-radius: var(--b-rad-table);
  column-gap: 1rem;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1rem;
}

.status-top-actionable-modifier {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 50%;
}

.transaction-detail-status-top > .status-active {
  color: var(--primary-1st-color);
}

.transaction-detail-status-top > .status-inactive {
  color: rgba(10, 77, 60, 0.4);
}

.transaction-detail-status-top > .status-fail {
  color: var(--semantic-error);
  font-weight: 600;
}

.transaction-detail-status-bottom {
  align-items: center;
  background: var(--neutral-4th-color);
  border-radius: 0 0 var(--b-rad-table) var(--b-rad-table);
  display: flex;
  height: 50%;
  justify-content: space-between;
  padding: 1rem;
}

.transaction-detail-status-bottom > h6 {
  font-weight: 600;
}

.transaction-detail-status-bottom > div {
  column-gap: 0.5rem;
  display: flex;
}

/* ? CONTENTS/BODY WRAP & CONTENTS SECTION */
.adm-transaction-detail-contents-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

/* * TRANSACTION DETAIL 1ST ROW WRAP & CONTENTS ON CONTENTS/BODY SECTION */
.adm-transaction-detail-1stRow,
.adm-transaction-detail-2ndRow {
  column-gap: 1.5rem;
  display: flex;
}

.adm-transaction-detail-1stRow h5,
.adm-transaction-detail-2ndRow h5 {
  font-weight: 600;
}

.transaction-detail-1stRow-left {
  background-color: var(--neutral-5th-color);
  border-radius: var(--b-rad-table);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1rem;
  width: 70%;

  box-shadow: var(--b-shadow-root);
  -webkit-box-shadow: var(--b-shadow-root);
  -moz-box-shadow: var(--b-shadow-root);
}

.transaction-detail-1stRow-left > div:first-of-type {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.transaction-detail-1stRow-left > div:first-of-type button {
  align-items: center;
  background: var(--neutral-5th-color);
  border: none;
  color: var(--primary-4th-color);
  column-gap: 0.25rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.transaction-detail-1stRow-left > div:first-of-type button:hover {
  color: var(--primary-3rd-color);
  transition: 300ms;
}

.transaction-detail-1stRow-left > div:first-of-type button:disabled {
  cursor: default;
  color: var(--neutral-3rd-color);
}

.transaction-detail-sufficient-label {
  background-color: #d0e4da;
  border-radius: var(--b-rad-table);
  color: var(--semantic-success);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.transaction-detail-insufficient-label {
  background-color: #f0dad4;
  border-radius: var(--b-rad-table);
  color: var(--semantic-error);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.transaction-detail-done-label {
  background-color: rgba(39, 160, 227, 0.8);
  border-radius: var(--b-rad-table);
  color: var(--neutral-5th-color);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.transaction-detail-requested-label {
  background-color: #fef0d7;
  border-radius: var(--b-rad-table);
  color: #ef8943;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.transaction-detail-1stRow-right {
  background-color: var(--neutral-5th-color);
  border-radius: var(--b-rad-table);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1rem;
  width: 30%;

  box-shadow: var(--b-shadow-root);
  -webkit-box-shadow: var(--b-shadow-root);
  -moz-box-shadow: var(--b-shadow-root);
}

/* * TRANSACTION DETAIL 2ND ROW WRAP & CONTENTS ON CONTENTS/BODY SECTION */
.adm-transaction-detail-2ndRow h5,
.adm-transaction-detail-2ndRow p {
  color: var(--neutral-2nd-color);
  margin: 0;
}

.adm-transaction-detail-2ndRow p {
  font-size: 0.875em;
}

.transaction-detail-2ndRow-left,
.transaction-detail-2ndRow-mid,
.transaction-detail-2ndRow-right {
  background-color: var(--neutral-5th-color);
  border-radius: var(--b-rad-table);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  row-gap: 1rem;
  width: 33.33%;

  box-shadow: var(--b-shadow-root);
  -webkit-box-shadow: var(--b-shadow-root);
  -moz-box-shadow: var(--b-shadow-root);
}

.transaction-detail-2ndRow-left > div {
  border: 1px solid var(--neutral-3rd-color);
  border-radius: var(--b-rad-table);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 0.5rem;
}

.transaction-detail-2ndRow-left > div > p {
  font-size: 0.75rem;
}

.transaction-detail-2ndRow-left > div > p:first-of-type,
.transaction-detail-2ndRow-mid > div:not(:nth-of-type(3)) > p:last-of-type,
.transaction-detail-2ndRow-right > div > p:last-of-type {
  color: var(--neutral-1st-color);
  font-weight: 500;
}

.transaction-detail-2ndRow-mid > div:last-of-type > span {
  cursor: pointer;
  color: var(--primary-4th-color);
  font-weight: 600;
}

.transaction-detail-2ndRow-mid > div:last-of-type > span:hover {
  color: var(--primary-3rd-color);
  transition: 300ms;
}

.transaction-detail-2ndRow-mid > div,
.transaction-detail-2ndRow-right > div {
  display: flex;
  justify-content: space-between;
}

/* ? PAYMENT PRROF MODAL CONTENT */
.detailTrx-payproof-modal-heading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.5rem;
  row-gap: 1rem;
}

.detailTrx-payproof-modal-heading > h4,
.detailTrx-payproof-modal-heading > h6 {
  margin: 0;
}

.detailTrx-payproof-modal-body {
  align-items: center;
  display: flex;
  justify-content: center;
}

.detailTrx-payproof-modal-body > img {
  height: 30rem;
  width: 30rem;
}

.detailTrx-payproof-modal-foot {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.modal-stock-request {
  min-width: 50% !important;
  max-width: 50%;
}

.modal-request-cardaddress {
  border: 1px solid #cacaca;
  background-color: #fff;
  border-radius: 8px;
}

.fs14-500-black {
  font-size: 0.875em;
  font-weight: 500;
  color: #070707;
}

.modal-request-wrapperinput {
  border: 1px solid #cacaca;
  border-radius: 8px;
  overflow: hidden;
  width: 90%;
}

.modal-request-plusminus {
  border: none;
  background-color: transparent;
  padding: 8px 10px;
}

.modal-request-input {
  border: none;
  background-color: #fff;
  font-size: 0.875em;
  color: #b24629;
  font-weight: 500;
  text-align: center;
  width: 80%;
}

.modal-request-input:enabled {
  outline: none;
}

.modal-request-submit {
  border: none;
  background-color: #b24629;
  border-radius: 100px;
  color: #fff;
}
.modal-request-cancel {
  border: 1px solid #b24629;
  box-sizing: border-box;
  border-radius: 100px;
  background-color: transparent;
}
