:root {
  --primary-1st-color: #0a4d3c;
  --primary-2nd-color: #fcb537;
  --primary-3rd-color: #8d280c;
  --primary-4th-color: #b24629;
  --primary-5th-color: #f1ede7;

  --neutral-1st-color: #070707;
  --neutral-2nd-color: #5a5a5a;
  --neutral-3rd-color: #cacaca;
  --neutral-4th-color: #f4f4f4;
  --neutral-5th-color: #ffffff;

  --semantic-error: #cb3a31;
  --semantic-warning: #ef8943;
  --semantic-success: #43936c;
  --semantic-info: #27a0e3;
}

.btn-tambah-alamat {
  border-radius: 100px;
  background-color: #b24629;
  font-size: 0.9em;
  font-family: Poppins;
  color: white;
  border: none;
  font-weight: 600;
  width: 13rem;
  padding: 0.625rem 0.75rem;
  /* align-items: flex-ends; */

  /* width: px; */
  /* margin-left: 50%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1.5%;
  padding-top: 1.5%; */
}
.daftar-alamat {
  font-weight: 500;
  margin-top: 2%;
  /* font-size: 14px; */
}

.btn-tambah-alamat:hover {
  background-color: var(--primary-3rd-color);
}
.h6-detail-address {
  width: 60%;
}
.checkout-listadd-wrapper {
  height: 300px;
  overflow: auto;
  font-size: 0.75em;
}
.cart-detail-border {
  border-bottom: 1px solid rgb(226, 226, 226);
}
.btn-delete-alamat {
  border-radius: 100px;
  color: #b24629;
  /* border: 1px solid #b24629; */
  border: none;
  background-color: #ffffff;
  /* padding-left: 5%;
  padding-right: 5%; */
  font-weight: 600;
  padding: 0.625rem 0.75rem;
  /* margin-top: 5%; */
  /* padding-bottom: 2%;
  padding-top: 2%; */
  /* border-radius: 100px;
  background-color: #b24629;
  font-size: 0.9em;
  font-family: Poppins;
  color: white;
  border: none;
  font-weight: 600;
  width: 90%;
  margin-top: 4%; */
  /* position: fixed; */

  /* position: ; */
  /* align-items: flex-ends; */

  /* width: px; */
  /* margin-left: 0%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 5%;
  padding-top: 5%; */
}
.btn-delete-alamat:hover {
  color: #8d280c;
}
.btn-edit-alamat {
  border-radius: 100px;
  color: #b24629;
  /* border: 1px solid #b24629; */
  border: none;
  background-color: #ffffff;
  padding: 0.625rem 0.75rem;
  /* padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%; */
  font-weight: 600;
  /* margin-bottom: 3%; */
  /* padding-top: 2%; */
  /* border-radius: 100px;
  color: #b24629;
  font-size: 0.9em;
  font-family: Poppins; */
  /* color: white; */

  /* font-weight: 600;
  width: 90%;
  border: 2px solid #b24629;
  background-color: #ffffff; */
  /* position: fixed; */

  /* align-items: flex-ends; */

  /* width: px; */
  /* margin-left: 0%;
  margin-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 5%;
  padding-top: 5%; */
}
.btn-edit-alamat:hover {
  color: #8d280c;
}
.render-address {
  border: 1px solid #b24629;
  border-radius: 15px;
  padding-left: 3%;
  padding-top: 3%;
  padding-bottom: 2%;
  margin-top: 2%;
  width: 100%;

  column-gap: 1rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.test-kanan {
  width: 80% !important;
  display: flex;
  flex-direction: row;
}
.test-kiri {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  /* margin-left: 2%; */
  /* display: flex;
  flex-direction: row; */
}
.btn-alamat-utama {
  border-radius: 100px;
  color: #b24629;
  /* border: 1px solid #b24629; */
  border: none;
  background-color: #ffffff;
  /* padding-left: 5%;
  padding-right: 5%; */
  /* padding-bottom: 2%;
  padding-top: 2%; */
  padding: 0.625rem 0.75rem;
  font-weight: 600;

  /* margin-top: 50%; */
  width: max-content;
}
.btn-alamat-utama:hover {
  /* border: 1px solid var(--primary-3rd-color); */
  color: #8d280c;
}
.main-address {
  background-color: var(--neutral-4th-color);
  /* position: sticky; */
  /* width: 100%; */
  /* margin-left: %; */
  /* padding-bottom: 30%; */
  /* top: 0;
  right: 0; */
  /* border-top-right-radius: 12px; */
  border-radius: 8px;
  color: var(--neutral-2nd-color);
  font-size: 1em;
  padding: 0.5rem;
}
.input-form {
  position: static;
  width: 466px;
  height: 42px;
  left: 0px;
  top: 30px;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 8px;
}
.btn-batal-alamat {
  justify-content: center;
  align-items: center;
  /* padding: 10px 16px; */
  /* margin-left: 5%; */
  /* padding-left: 10%;
  padding-right: 10%; */
  color: #b24629;
  font-size: 0.9em;
  background: #ffffff;
  font-weight: 600;
  padding: 0.625rem 0.75rem;
  width: 10rem;
  /* Primary/Burnt Orange */
  /* margin-top: 7%; */

  border: 1px solid #b24629;
  box-sizing: border-box;
  border-radius: 100px;
}
.btn-batal-alamat:hover {
  border: 1px solid var(--primary-3rd-color);
  color: #8d280c;
}
.btn-simpan-alamat {
  justify-content: center;
  align-items: center;
  color: #ffffff;
  /* padding-left: 9%;
  padding-right: 9%; */
  font-weight: 600;
  font-size: 0.9em;
  /* margin-top: 7%;
  margin-left: 35%; */
  padding: 0.625rem 0.75rem;
  width: 10rem;

  /* Primary/Burnt Orange */
  border: none;
  background: #b24629;
  /* box-sizing: border-box; */
  border-radius: 100px;
}
.btn-simpan-alamat:hover {
  background-color: var(--primary-3rd-color);
}
.dropdown-city {
  margin-left: 5%;
}
/* .dropdown-form {
  padding-right: 5%;
  width: 466px;
} */
/* .dropdown-form {
  position: static;
  width: 466px;
  height: 42px;
  left: 0px;
  top: 30px;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 8px;
} */

.render-address-test {
  padding: 1rem;
  border: 1px solid var(--primary-3rd-color);
  border-radius: 12px;
  /* row-gap: 1rem; */
}
.parent-address {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.checkbox-alamat-utama {
  margin-left: 3px;
}
.alamat-utama-text {
  margin-left: 4px;
}
.checkbox-input:checked {
  background-color: #0a4d3c;
}
.checkbox-input {
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
.belum-ada-alamat {
  font-family: Poppins;
  text-align: center;
  font-size: 1.5em;
  font-weight: 500;
}
.btn-yes-delete {
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-weight: 500;
  font-size: 0.9em;
  margin-top: 7%;
  margin-left: 35%;
  letter-spacing: 1px;
  /* Primary/Burnt Orange */
  border: none;
  background: #b24629;
  /* box-sizing: border-box; */
  border-radius: 100px;
}
.btn-cancel-delete {
  justify-content: center;
  align-items: center;
  /* padding: 10px 16px; */
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: 5%;
  padding-left: 9%;
  padding-right: 9%;
  color: #b24629;
  font-size: 0.9em;
  background: #ffffff;
  font-weight: 600;
  /* Primary/Burnt Orange */
  margin-top: 7%;

  border: 1px solid #b24629;
  box-sizing: border-box;
  border-radius: 100px;
}
.btn-cancel-delete:hover {
  border: 1px solid var(--primary-3rd-color);
  color: #8d280c;
}
.btn-yes-delete:hover {
  background-color: var(--primary-3rd-color);
}
.btn-simpan-alamat:disabled {
  color: solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.non-main-address {
  width: 90%;
}

.address-row-override {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.modal-address-row-override {
  column-gap: .5rem;
  justify-content: flex-end;
  margin: 1rem 0 0;
  width: 100% !important;
}

/* ? TABLET SIZE MD */
@media (max-width: 991px) {
  .test-kanan {
    width: 100% !important;
  }

  .modal-address-override {
    width: 75%;
  }

  .input-form {
    width: 100%;
  }

  .modal-address-row-override {
    flex-wrap: nowrap;
  }
}

/* ? MOBILE SMALL SM */
@media (max-width: 575px) {
  .address-row-override {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .btn-tambah-alamat {
    width: 100%;
  }

  .test-kiri {
    flex-direction: column;
  }

  .test-kiri > div:first-of-type {
    order: 2;
  }

  .test-kiri > div:last-of-type {
    order: 1;
  }

  .test-kanan {
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .btn-alamat-utama {
    width: 100%;
  }

  .modal-address-override {
    width: 85%;
  }

  .modal-address-row-override {
    flex-direction: column;
    row-gap: 1rem;
  }

  .btn-batal-alamat {
    order: 2;
    width: 100%;
  }

  .btn-simpan-alamat {
    order: 1;
    width: 100%;
  }
}