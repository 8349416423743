.product-filter-container {
  height: auto;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.product-filter-font {
  font-weight: 600;
}

.product-filter-by {
  font-size: 0.75em;
  font-weight: 600;
}

.product-category-content {
  font-size: 0.75em;
}

.product-label {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.product-border {
  border-bottom: 1px solid rgb(223, 222, 222);
}

.product-filter-harga {
  font-size: 0.75em;
  color: #070707;
  padding: 3% 5%;
  border-radius: 12px;
  border: solid 1px #cacaca;
}

.product-filter-harga:enabled {
  outline-color: #b24629;
}

input::-webkit-input-placeholder {
  font-size: 1em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.product-filter-nama {
  font-size: 0.875em;
  border: none;
  cursor: text;
}

.product-filter-nama:enabled {
  outline-color: transparent !important;
}

.product-filter-nama-wrapper {
  border-radius: 12px;
  padding: 1.3%;
  border: solid 1px #cacaca;
  background-color: #fff;
  width: 40%;
}

.product-filter-nama:enabled {
  outline-color: #b24629;
}

.product-sort {
  padding: 12px;
  border-radius: 12px;
  border: solid 1px #cacaca;
  background-color: #fff;
  cursor: pointer;
}

.product-list-wrapper {
  margin-top: 2%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 24px;
}

.product-card {
  justify-self: center;
}

.product-sidebar {
  padding: 0 !important;
  width: 19%;
}

.product-sidebar-wrapper {
  box-shadow: 0 1px 12px 0 rgba(231, 203, 162, 0.16);
  background-color: #fff;
  border-radius: 8px;
}

.product-sidebar-title {
  padding: 6.5% 8.5%;
  background-color: #0a4d3c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.product-sort-content {
  padding: 16px;
}

.product-title-name {
  font-size: 0.875em;
  font-weight: 500;
  color: #ffffff;
}

.product-title-reset {
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
}

.product-content-select {
  padding: 9px 12px;
  border: 1px solid black;
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
  cursor: pointer;
}

.product-checkbox {
  position: relative;
}

.product-checkmark {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 1%;
  text-align: center;
  /* opacity: 0; */
}

.product-category-label {
  font-size: 0.75em;
  font-weight: 500;
}

.product-input-price {
  border: none;
  font-size: 0.875em;
}

.product-input-price:enabled {
  outline-color: transparent;
}

.product-price-wrapper {
  padding: 7%;
  border-radius: 8px;
  border: solid 1px #cacaca;
}

.product-address {
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1.67;
  color: #070707;
}

.product-change-address {
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
}

.product-content {
  /* padding: 0 !important; */
  width: 79.5%;
}

.product-show-font {
  font-size: 0.75em;
  font-weight: 500;
}

.product-sort-list {
  position: absolute;
  background-color: #fff;
  color: #5a5a5a;
  font-size: 0.875em;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  left: 0;
  top: 120%;
  z-index: 999;
}

.product-sort-sub {
  padding: 12px;
  cursor: pointer;
}

.product-sort-sub:hover {
  color: #b24629;
}

.product-sort-sub-active {
  color: #b24629;
}

.skeleton-category-checkbox {
  width: 1rem;
  height: 1rem;
  background-color: hsl(200, 20%, 70%);
  border-radius: 0.25rem;
  opacity: 0.7;
}

.skeleton-category-name {
  width: 75%;
  height: 1rem;
  background-color: hsl(200, 20%, 70%);
  border-radius: 0.25rem;
  opacity: 0.7;
}

.skeleton-category-anim {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.product-empty-data {
  font-size: 1.25em;
  font-weight: 600;
  text-align: center;
}

.prod-sort-resp {
  background-color: #0a4d3c;
  font-size: 0.625em;
  font-weight: 500;
  color: #fff;
  border-radius: 8px;
}

.modal-sorting {
  height: 80%;
  width: 100% !important;
}

.modal-filtering {
  height: 100%;
  width: 100%;
}

.prod-modal-filter-cat {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 480px) {
  .product-list-wrapper {
    grid-template-columns: 1fr 1fr !important;
  }

  .product-card:nth-child(even) {
    justify-self: end;
  }

  .product-card:nth-child(odd) {
    justify-self: start;
  }

  .product-content {
    width: 100%;
  }

  .product-show-font {
    width: 100%;
    order: 2;
  }

  .product-filter-nama-wrapper {
    padding: 4%;
    width: 100%;
    order: 0;
  }

  .prod-filternsort-resp {
    order: 1;
  }

  .product-price-wrapper {
    padding: 4%;
  }
}

@media only screen and (max-width: 1024px) {
  .product-list-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .product-content {
    /* padding: 0 !important; */
    width: 100%;
  }

  .product-show-font {
    width: 100%;
    order: 2;
  }

  .product-filter-nama-wrapper {
    width: 100%;
    order: 0;
  }

  .prod-filternsort-resp {
    order: 1;
  }

  .product-price-wrapper {
    padding: 3%;
  }
}
