:root {
  --primary-1st-color: #0a4d3c;
  --primary-2nd-color: #fcb537;
  --primary-3rd-color: #8d280c;
  --primary-4th-color: #b24629;
  --primary-5th-color: #f1ede7;

  --neutral-1st-color: #070707;
  --neutral-2nd-color: #5a5a5a;
  --neutral-3rd-color: #cacaca;
  --neutral-4th-color: #f4f4f4;
  --neutral-5th-color: #ffffff;

  --semantic-error: #cb3a31;
  --semantic-warning: #ef8943;
  --semantic-success: #43936c;
  --semantic-info: #27a0e3;
}

/* .regis-page {
  padding: 0% !important;
} */

.image {
  width: 50%;
}
.login-main-wrap {
  align-items: center;
  background-color: var(--primary-5th-color);
  display: flex;
  height: max-content;
  width: 100% !important;
  justify-content: center;
  margin-top: 0px;
  box-sizing: border-box;
  padding: 40px 0;
}

.login-sub-wrap {
  display: flex;
  height: 660px;
  width: 1120px;
  background-color: var(--neutral-5th-color);
  border-radius: 12px;

  box-shadow: 0px 1px 6px #d7d7d8;
  -webkit-box-shadow: 0px 1px 6px #d7d7d8;
  -moz-box-shadow: 0px 1px 6px #d7d7d8;
}

.login-left-image-wrap {
  width: 50%;
  height: 100%;
}

.login-right-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 64px;
  box-sizing: border-box;
}

.label-text {
  /* padding-right: 50% !important; */
  font-weight: 600;
  text-align: left;
  font-family: Poppins;
  /* font-size: 16px; */
}

.input-form {
  margin-top: 4%;
}
.text-input {
  width: 370px;
  height: 42px;
  margin: 15px 0 16px !important;
}
.regis-text {
  margin-bottom: 10%;
  font-family: Poppins;
}

.btn-login {
  border-radius: 100px;
  background-color: #b24629;
  font-size: 1.1em;
  font-family: Poppins;
  color: white;
  border: none;
  font-weight: 600;
  align-items: center;
  width: 180px;
  margin: 30px 32px 0 0;
  padding: 0.625rem 0.75rem;
  /* padding-left: 15%;
  padding-right: 18%;
  padding-bottom: 2%;
  padding-top: 2%; */
}
.sudah-punya-akun-text {
  margin-top: 10%;
  font-family: Poppins;
}
.link-daftar {
  margin-top: 41px;
  margin-left: 4px;
  font-family: Poppins;
  color: #b24629;
  font-weight: 600;
}

.checkbox-form {
  width: 300px;
  margin-left: -0.5rem;
}
.checkbox-input {
  margin-top: 3px !important;
}
.showpassword {
  margin-left: 2% !important;
  font-family: Poppins;
}
.link-lupa-password {
  /* margin-left: 42%; */
  text-align: left;
  margin-bottom: 4px;
  color: #b24629;
  cursor: pointer;
}
.form-kata-sandi {
  justify-content: space-between;
  margin-top: 2%;
  margin-left: 1px;
  margin-right: 0;
}
.text-input-password {
  width: 370px;
  height: 42px;
  margin: 12px 0 16px !important;
}

.btn-login:hover {
  background-color: var(--primary-3rd-color);
}
.link-daftar:hover {
  color: var(--primary-3rd-color);
}
.link-lupa-password:hover {
  color: var(--primary-3rd-color);
}

.login-right-form-wrap > .d-flex a {
  margin: 0;
}

/* ? DESKTOP SIZE LG */
@media (max-width: 1199px) {
  .login-main-wrap {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .login-sub-wrap {
    width: calc(1120px - 5rem) !important;
  }

  .login-left-image-wrap {
    width: 60% !important;
  }
}

/* ? TABLET SIZE MD */
@media (max-width: 991px) {
  .login-main-wrap {
    padding: 0 !important;
  }

  .login-sub-wrap {
    border-radius: 0 !important;
    flex-direction: column;
    height: max-content !important;
    min-height: 100vh !important;
    padding: 5rem 0;
    width: 100%;
  }

  .login-left-image-wrap {
    display: none;
  }

  .login-right-form-wrap {
    padding: 0 1.5rem !important;
  }

  .regis-text {
    margin-bottom: 1.5rem !important;
  }

  .btn-login {
    margin-right: 0 !important;
    margin-top: 2.5rem !important;
  }

  .login-right-form-wrap > .d-flex {
    flex-direction: column;
    width: 30rem;
  }

  .login-right-form-wrap > .d-flex > h6 {
    margin-top: 2rem;
  }
}

/* ? MOBILE SMALL SM */
@media (max-width: 575px) {
  /* .login-right-form-wrap {
    width: 100vw !important;
  } */
  .login-right-form-wrap > .d-flex {
    width: 100%;
  }

  .label-text {
    width: 100%;
  }

  .text-input,
  .text-input-password {
    width: 100% !important;
  }

  .btn-login {
    width: 100%;
  }
}