:root {
    --primary-1st-color: #0A4D3C;
    --primary-2nd-color: #FCB537;
    --primary-3rd-color: #8D280C;
    --primary-4th-color: #B24629;
    --primary-5th-color: #F1EDE7;

    --neutral-1st-color: #070707;
    --neutral-2nd-color: #5A5A5A;
    --neutral-3rd-color: #CACACA;
    --neutral-4th-color: #F4F4F4;
    --neutral-5th-color: #FFFFFF;

    --semantic-error: #CB3A31;
    --semantic-warning: #EF8943;
    --semantic-success: #43936C;
    --semantic-info: #27A0E3;

    --b-rad-root: 12px;
    --b-rad-table: 8px;
    --b-shadow-root: 0px 1px 6px #D7D7D8;
}

/* ? GROUPED UTILITY CLASS FROM DIFFERENT SECTIONS */
.adm-dashboard-header-wrap > h4,
.adm-dashboard-header-right > h4,
.adm-dashboard-1stRow-left h4,
.adm-dashboard-1stRow-left h6,
.dashboard-1stRow-right-heading h6,
.dashboard-2ndRow-left-heading h6,
.dashboard-2ndRow-right-heading h6,
.dashboard-3rdRow-left-heading h6,
.dashboard-3rdRow-right-heading h6,
.dashboard-3rdRow-mid-heading h6,
.dashboard-4thRow-left-heading h6,
.dashboard-4thRow-right-heading h6 {
    margin: 0
}

.dashboard-1stRow-left-top,
.dashboard-1stRow-left-bottom {
    column-gap: 1.5rem;
    display: flex;
    height: 100%;
    width: 100%;
}

.dashboard-1stRow-left-top > div,
.dashboard-1stRow-left-bottom > div {
    align-items: flex-start;
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-root);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    row-gap: 1rem;
    text-align: left;
    width: 50%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.dashboard-1stRow-right-heading,
.dashboard-2ndRow-left-heading,
.dashboard-2ndRow-right-heading,
.dashboard-3rdRow-left-heading,
.dashboard-3rdRow-right-heading {
    color: var(--neutral-2nd-color);
    column-gap: 1.5rem;
    display: flex;
}

.dashboard-1stRow-right-heading > h6,
.dashboard-2ndRow-left-heading > h6,
.dashboard-2ndRow-right-heading > h6,
.dashboard-3rdRow-left-heading > h6,
.dashboard-3rdRow-right-heading > h6,
.dashboard-4thRow-left-heading h6,
.dashboard-4thRow-right-heading h6 {
    font-size: clamp(0.75rem, 1vw, 1rem);
}

/* ? MAIN WRAPPER SECTION */
.adm-dashboard-main-wrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1.5rem;
}

/* ? HEADER WRAP & CONTENTS SECTION */
.adm-dashboard-header-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.adm-dashboard-header-wrap > h4 {
    font-weight: 600;
}

.adm-dashboard-header-right {
    align-items: center;
    column-gap: 1rem;
    display: flex;
    justify-content: center;
}

.adm-dashboard-header-right > h4 {
    color: var(--neutral-2nd-color);
    font-size: 1.375rem;
}

/* ? FILTER YEAR DROPDOWN MENU */
.adm-dashboard-dropdown-wrap {
    position: relative;
}

.adm-dashboard-dropdown-btn {
    background-color: var(--neutral-5th-color);
    border: none;
    border-radius: var(--b-rad-table);
    color: var(--neutral-2nd-color);
    cursor: pointer;
    height: 30px;
    width: 88px;
}

.adm-dashboard-dropdown-btn > img {
    margin-top: -2.2px;
    margin-left: 4px;
    transition: 300ms;
}

.adm-dashboard-dropdown-menu {
    align-items: flex-start;
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-table);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    margin-top: 5px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: 300ms;
    width: 88px;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.adm-dashboard-dropdown-menu > li {
    align-items: center;
    color: var(--neutral-2nd-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
}

.adm-dashboard-dropdown-menu > li:hover {
    color: var(--primary-4th-color);
    transition: 300ms;
}

.adm-dashboard-dropdown-selected {
    color: var(--primary-4th-color) !important;
    cursor: default !important;
}

/* ? CONTENTS/BODY WRAP & CONTENTS SECTION */
.adm-dashboard-contents-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    row-gap: 1.5rem;
}

/* ? MOSTLY 1ST ROW CONTAINER SECTION */
.adm-dashboard-contents-1stRow {
    column-gap: 1.5rem;
    display: flex;
    height: 320px;
    width: 100%;
}

.adm-dashboard-1stRow-left {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;
    width: 40%;
}

.adm-dashboard-1stRow-left h6 {
    font-size: clamp(0.75rem, 1vw, 1rem);
    color: var(--neutral-2nd-color);
    font-weight: 400;
}

.adm-dashboard-1stRow-left h4 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.adm-dashboard-1stRow-right {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-root);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    row-gap: 1.5rem;
    text-align: left;
    width: 60%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.dashboard-1stRow-right-chart {
    display: flex;
    height: calc(100% - 5% - 1.5rem);
    width: 100%;
}

/* ? MOSTLY 2ND ROW CONTAINER SECTION */
.adm-dashboard-contents-2ndRow {
    column-gap: 1.5rem;
    display: flex;
    height: 424px;
    width: 100%;
}

.adm-dashboard-2ndRow-left {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-root);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    row-gap: 1.5rem;
    text-align: left;
    width: 60%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.dashboard-2ndRow-left-chart {
    display: flex;
    height: 90%;
    width: 100%;
}

.adm-dashboard-2ndRow-right {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-root);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    row-gap: 1.5rem;
    text-align: left;
    width: 40%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.dashboard-2ndRow-right-chart {
    display: flex;
    height: 85%;
    margin: auto;
    width: 90%;
}

/* ? MOSTLY 3RD ROW CONTAINER SECTION */
.adm-dashboard-contents-3rdRow {
    display: flex;
    height: max-content;
    width: 100%;
}

.adm-dashboard-3rdRow {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-root);
    column-gap: 1.5rem;
    display: flex;
    padding: 1.5rem;
    row-gap: 2rem;
    width: 100%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.adm-dashboard-3rdRow > div {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 50%;
}

/* ? MOSTLY 4TH ROW CONTAINER SECTION */
.adm-dashboard-contents-4thRow {
    column-gap: 1.5rem;
    display: flex;
    height: max-content;
    width: 100%;
}

.adm-dashboard-4thRow-left,
.adm-dashboard-4thRow-right {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-root);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    row-gap: 1rem;
    width: 50%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.dashboard-4thRow-left-heading,
.dashboard-4thRow-right-heading {
    color: var(--neutral-2nd-color);
    display: flex;
}

/* ? EMPTY STATE & SPINNER SECTIONS */
.empty-state-center {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.dashboard-spinner-wrap {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}