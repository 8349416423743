.card-category-rectangle {
  width: 100px;
  height: 165px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 1px 12px 0 #d4c7b2;
}

.card-category-inner-circle {
  border-radius: 100%;
  background-color: #f4f4f4;
}

.card-category-rectangle:hover {
  background-color: #b24629;
  transition: 200ms;
}

.card-category-rectangle:hover .card-category-inner-circle {
  background-color: white;
  transition: 200ms;
}

.card-category-rectangle:hover .card-category-rectangle-txt {
  color: white;
}
