:root {
    --primary-1st-color: #0A4D3C;
    --primary-2nd-color: #FCB537;
    --primary-3rd-color: #8D280C;
    --primary-4th-color: #B24629;
    --primary-5th-color: #F1EDE7;

    --neutral-1st-color: #070707;
    --neutral-2nd-color: #5A5A5A;
    --neutral-3rd-color: #CACACA;
    --neutral-4th-color: #F4F4F4;
    --neutral-5th-color: #FFFFFF;

    --semantic-error: #CB3A31;
    --semantic-warning: #EF8943;
    --semantic-success: #43936C;
    --semantic-info: #27A0E3;

    --b-rad-root: 12px;
    --b-rad-table: 8px;
    --b-shadow-root: 0px 1px 6px #D7D7D8;
}

/* ? MAIN WRAPPER & CONTENTS SECTION */
.loading-app-main-wrap {
    align-items: center;
    background-color: var(--primary-5th-color);
    color: var(--neutral-2nd-color);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    min-height: 90vh;
    row-gap: 3rem;
    width: 100%;
}

.loading-app-main-wrap > h4 {
    animation: fadeInOut 2.3s linear infinite;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-o-keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@-ms-keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}