.payment-container {
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 32px 80px;
}

.payment-count-wrapper {
  width: 32px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cb3a31;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 9px 10px;
  font-size: 1.25em;
  color: #cb3a31;
  font-weight: 600;
}

.payment-count-separate {
  font-size: 1.25em;
  color: #cb3a31;
  font-weight: 400;
}

.payment-note-wrapper {
  border: 1px dashed #cacaca;
  border-radius: 8px;
}

.payment-note-border {
  border-bottom: 1px dashed #cacaca;
}

.payment-btn-detail {
  background: #ffffff;
  border: 1px solid #b24629;
  box-sizing: border-box;
  border-radius: 100px;
  color: #b24629;
  font-size: 0.875em;
  font-weight: 600;
}

.fs12-400-gray {
  font-size: 0.75em;
  font-weight: 400;
  color: #5a5a5a;
}

.fs10-400-black {
  font-size: 0.625em;
  font-weight: 400;
  color: #070707;
}

.fs10-400-gray {
  font-size: 0.625em;
  font-weight: 400;
  color: #5a5a5a;
}

.fs14-600-red {
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
}

.payment-howtopay {
  line-height: 16px;
}

@media only screen and (max-width: 480px) {
  .payment-btn-detail {
    width: 100% !important;
  }

  .payment-btn-belanja-resp {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .payment-container-resp {
  }
}
