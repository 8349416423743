:root {
  --primary-1st-color: #0a4d3c;
  --primary-2nd-color: #fcb537;
  --primary-3rd-color: #8d280c;
  --primary-4th-color: #b24629;
  --primary-5th-color: #f1ede7;

  --neutral-1st-color: #070707;
  --neutral-2nd-color: #5a5a5a;
  --neutral-3rd-color: #cacaca;
  --neutral-4th-color: #f4f4f4;
  --neutral-5th-color: #ffffff;

  --semantic-error: #cb3a31;
  --semantic-warning: #ef8943;
  --semantic-success: #43936c;
  --semantic-info: #27a0e3;

  --b-rad-root: 12px;
  --b-shadow-root: 0px 1px 6px #d7d7d8;

  /* ? REACT TOASTIFY ROOT STYLING FOR ADMIN & NON USER/NON ADMIN ROUTE */
  --toastify-toast-width: 400px !important;
  --toastify-font-family: "Poppins", "Open Sans", sans-serif;
  --toastify-text-color-light: var(--neutral-1st-color) !important;

  /* * Success Toast */
  --toastify-icon-color-success: var(--semantic-success);
  --toastify-color-progress-success: var(--semantic-success);

  /* * Error Toast */
  --toastify-icon-color-error: var(--semantic-error) !important;
  --toastify-color-progress-error: var(--semantic-error) !important;
}

/* ! Cek bagian ini, apakah ada yang mempengaruhi/tidak */
/* .App {
  text-align: center;
} */

body {
  margin: 0;
  background-color: #f1ede7;
  font-family: "Poppins", "Open Sans", sans-serif;
}

.text-link {
  text-decoration: none !important;
  color: initial;
}

.text-link:hover {
  color: initial;
}
/* ! ------------------------------------------------- */

/* ? REACT TOASTIFY CUSTOM STYLING FOR ADMIN & NON USER/NON ADMIN ROUTE */
.Toastify__toast-theme--light {
  font-size: 0.95em;
  font-weight: 400;
}

.Toastify__close-button {
  color: var(--neutral-1st-color) !important;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
  color: var(--neutral-2nd-color) !important;
}