.header-bar {
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  z-index: 999;
  position: sticky;
  top: 0;
}

.header-right {
  margin-right: 2rem;
  position: relative;
  cursor: pointer;
}

.header-masuk {
  background-color: transparent;
  border: none;
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
  transition: all 0.3s;
}

.header-masuk:hover {
  color: #8d280c;
}

.header-btn-daftar {
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
  background-color: transparent;
  border: 1px solid #b24629;
  border-radius: 100px;
  transition: all 0.3s;
}

.header-btn-daftar:hover {
  border: 1px solid #8d280c;
  color: #8d280c;
}

.header-product {
  position: relative;
}

.header-container-login {
  min-width: 200px;
  max-width: 200px;
  height: 50px;
  padding: 1% 3%;
  border-radius: 100px;
  border: none;
  border: solid 1px #f4f4f4;
  background-color: #f4f4f4;
}

.header-login-image {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
}

.header-nophoto {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #fcb537;
}

.header-profile-username {
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
}

.header-down-category {
  width: auto;
  background-color: white;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  position: absolute;
  left: 0;
  /* transform: translate(50%, -50%); */
}

.header-ddcategory-wrapper {
  background-color: white;
}

.header-ddlist-category {
  border: none;
  background-color: transparent;
  font-size: 0.875em;
  font-weight: 600;
  color: #070707;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-kategori {
  position: relative;
  z-index: 99;
}

.header-focus-category {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 1em;
  font-weight: 600;
  z-index: 999;
}

.header-focus-border {
  width: 24px;
  height: 4px;
  background-color: #0a4d3c;
  border-radius: 100px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  left: 0;
  right: 0;
  text-align: center;
}

.header-down-profile-container {
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  position: absolute;
  font-size: 0.75em;
  top: 100%;
}

.header-profil-wrapper {
  position: relative;
}

.header-notif-cart {
  position: absolute;
  background-color: #b24629;
  padding: 1% 15%;
  border-radius: 100px;
  font-size: 0.5em;
  color: #e9e9e9;
  top: 0;
  right: -10%;
}

.header-dd-top {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.header-modal {
  position: relative;
  z-index: 1;
}

.header-dd-cart {
  position: absolute;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-radius: 12px;
  right: 0;
  margin-top: 100%;
  cursor: initial;
}

.header-cart-title {
  width: 250px !important;
  font-size: 0.75em;
  font-weight: 500;
  color: #070707;
}

.header-dd-image {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.header-dd-nameprod {
  font-size: 0.75em;
  font-weight: 600;
  color: #070707;
}

.header-dd-seecart {
  border: none;
  background-color: transparent;
  font-size: 0.675em;
  font-weight: 500;
  color: #b24629;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-dd-list {
  max-height: 240px;
  overflow: auto;
  border-radius: 8px !important;
}

@media only screen and (max-width: 360px) {
  .header-right {
    margin-right: 1rem;
  }
}
