:root {
  --primary-1st-color: #0a4d3c;
  --primary-2nd-color: #fcb537;
  --primary-3rd-color: #8d280c;
  --primary-4th-color: #b24629;
  --primary-5th-color: #f1ede7;

  --neutral-1st-color: #070707;
  --neutral-2nd-color: #5a5a5a;
  --neutral-3rd-color: #cacaca;
  --neutral-4th-color: #f4f4f4;
  --neutral-5th-color: #ffffff;

  --semantic-error: #cb3a31;
  --semantic-warning: #ef8943;
  --semantic-success: #43936c;
  --semantic-info: #27a0e3;
}

.login-container {
  box-shadow: 3px -4px 7px -2px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 3px -4px 7px -2px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 3px -4px 7px -2px rgba(0, 0, 0, 0.74);
  height: 80vh;
  margin-top: 5%;
}
/* .regis-page {
  padding: 0% !important;
} */

.image {
  width: 50%;
}
.login-main-wrap {
  align-items: center;
  background-color: var(--primary-5th-color);
  display: flex;
  height: max-content;
  width: 100% !important;
  justify-content: center;
  margin-top: 0px;
  box-sizing: border-box;
  padding: 40px 0;
}

.login-sub-wrap {
  display: flex;
  height: 660px;
  width: 1120px;
  background-color: var(--neutral-5th-color);
  border-radius: 12px;

  box-shadow: 0px 1px 6px #d7d7d8;
  -webkit-box-shadow: 0px 1px 6px #d7d7d8;
  -moz-box-shadow: 0px 1px 6px #d7d7d8;
}

.login-left-image-wrap {
  width: 50%;
  height: 100%;
}

.login-right-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 64px;
  box-sizing: border-box;
}

.label-text {
  /* padding-right: 50% !important; */
  font-weight: 600;
  text-align: left;
  font-family: Poppins;
  /* font-size: 16px; */
}

.input-form {
  margin-top: 4%;
}
.text-input {
  width: 370px;
  height: 42px;
  margin: 15px 0 16px !important;
}
.regis-text {
  margin-bottom: 10%;
  font-family: Poppins;
}

.btn-regis {
  border-radius: 100px;
  background-color: #b24629;
  font-size: 1.1em;
  font-family: Poppins;
  color: white;
  border: none;
  font-weight: 600;
  align-items: center;
  width: 180px;
  margin: 30px 32px 0 0;
  padding: 0.625rem 0.75rem;
  /* padding-left: 15%;
  padding-right: 18%;
  padding-bottom: 2%;
  padding-top: 2%; */
}
.sudah-punya-akun-text {
  margin-top: 10%;
  font-family: Poppins;
}
.link-masuk {
  margin-top: 41px;
  margin-left: 4px;
  font-family: Poppins;
  color: #b24629;
  font-weight: 600;
}
.checkbox-form {
  width: 300px;
  margin-left: -0.5rem;
}
.checkbox-input {
  margin-top: 3px !important;
}
.showpassword {
  margin-left: 2% !important;
  font-family: Poppins;
}

.btn-regis:hover {
  background-color: var(--primary-3rd-color);
}
.link-masuk:hover {
  color: var(--primary-3rd-color);
}
