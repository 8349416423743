.cart-left-container,
.cart-right-container {
  background-color: #fff;
  border-radius: 12px;
}

.cart-title {
  font-size: 1em;
  font-weight: 600;
  color: #0a4d3c;
}

.cart-border {
  border-bottom: 1px solid #f4f4f4;
}

.cart-list-img {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  object-fit: cover !important;
}

.cart-categoryprod {
  font-size: 0.75em;
  color: #ef8943;
}

.cart-nameprod,
.cart-priceprod {
  font-size: 0.875em;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cart-priceprod {
  color: #0a4d3c;
}

.cart-delete {
  border: none;
  background-color: transparent;
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
}

.cart-inputqty {
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
}

.cart-btn {
  border: none;
  background-color: transparent;
}

.cart-qty {
  font-size: 0.875em;
  border: none;
  color: #b24629;
  text-align: center;
}

.cart-qty:enabled {
  outline-color: transparent;
}

.cart-promo-text {
  font-size: 0.875em;
  font-weight: 500;
  color: #070707;
}

.cart-promo-show {
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
  font-size: 0.875em;
  cursor: pointer;
}

.cart-promo-input {
  font-size: 0.875em;
  border: none;
  cursor: pointer;
}

.cart-promo-input:enabled {
  outline-color: transparent;
}

.cart-terapkan {
  font-size: 0.875em;
  font-weight: 600;
  color: #cacaca;
}

.cart-totalprice {
  font-size: 0.75em;
  font-weight: 500;
  color: #5a5a5a;
}

.cart-total {
  font-size: 0.875em;
  font-weight: 500;
  color: #070707;
}

.cart-errorstock {
  background-color: #fbefef;
  border-radius: 8px;
}

.cart-del-modal {
  min-width: 25% !important;
  max-width: 25% !important;
  padding: 0 !important;
  overflow: hidden;
}

.cart-del-content {
  font-size: 0.875em;
  font-weight: 400;
}

.cart-del-btn {
  border: 1px solid #cacaca;
  background-color: transparent;
  padding: 10px 12px;
  font-size: 0.875em;
  /* font-weight: 500; */
}

@media only screen and (max-width: 480px) {
  .cart-left {
    width: 100%;
  }

  .cart-del-modal {
    min-width: 75% !important;
    max-width: 75% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .cart-del-modal {
    min-width: 75% !important;
    max-width: 75% !important;
  }
}
