:root {
    --primary-1st-color: #0A4D3C;
    --primary-2nd-color: #FCB537;
    --primary-3rd-color: #8D280C;
    --primary-4th-color: #B24629;
    --primary-5th-color: #F1EDE7;

    --neutral-1st-color: #070707;
    --neutral-2nd-color: #5A5A5A;
    --neutral-3rd-color: #CACACA;
    --neutral-4th-color: #F4F4F4;
    --neutral-5th-color: #FFFFFF;

    --semantic-error: #CB3A31;
    --semantic-warning: #EF8943;
    --semantic-success: #43936C;
    --semantic-info: #27A0E3;

    --b-rad-root: 12px;
    --b-rad-table: 8px;
    --b-shadow-root: 0px 1px 6px #D7D7D8;
}

/* ? UTILITY CLASS */
.adm-product-table-override {
    border-radius: var(--b-rad-table) !important;
    height: fit-content;
    overflow-y: hidden;
    padding: 1.5rem 1.5rem 2.6rem;

    box-shadow: var(--b-shadow-root) !important;
    -webkit-box-shadow: var(--b-shadow-root) !important;
    -moz-box-shadow: var(--b-shadow-root) !important;
}

/* ? GROUPED UTILITY CLASS FROM DIFFERENT SECTIONS */
.admWh-stock-filter-dropdown-wrap,
.admWh-stock-action-cell {
    position: relative;
}

.admWh-stock-filter-dropdown-btn:hover,
.admWh-stock-dropdown-btn:hover {
    border-color: var(--primary-4th-color);
    color: var(--primary-4th-color);
    transition: 400ms;
}

.admWh-stock-filter-dropdown-menu > li:hover,
.admWh-stock-dropdown-menu > a:hover > li,
.admWh-stock-dropdown-menu > li:hover {
    color: var(--primary-4th-color);
    transition: 300ms;
}

/* ? MAIN WRAPPER SECTION */
.admWh-stock-main-wrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1.5rem;
}

/* ? BREADCRUMB WRAP & CONTENTS SECTION */
.admWh-stock-breadcrumb-wrap {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
}

.admWh-stock-breadcrumb-wrap li { 
    /* Utk centering typography pd breadcrumb, karena lainnya pake Link dari react router dom */
    align-items: center;
    display: flex;
}

/* ? HEADER WRAP & CONTENTS SECTION */
.admWh-stock-header-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.admWh-stock-header-wrap > h4 {
    font-weight: 600;
}

.admWh-stock-header-wrap button {
    background-color: var(--primary-4th-color);
    border: none;
    border-radius: 100px;
    color: var(--neutral-5th-color);
    font-weight: 600;
    height: 42px;
    width: 160px;
}

.admWh-stock-header-wrap button:hover {
    background-color: var(--primary-3rd-color);
    transition: 300ms;
}

/* ? CONTENTS/BODY WRAP & CONTENTS SECTION */
.admWh-stock-contents-wrap {
    margin-top: 1.5rem;
}

.adm-edit-stock-txtBtn {
    cursor: pointer;
    color: var(--primary-4th-color);
    font-weight: 600;
}

.adm-edit-stock-txtBtn:hover {
    color: var(--primary-3rd-color);
}

/* ? DROPDOWN MENU FILTER PRODUCT PER PAGE ON TOP RIGHT OF TABLE */
.admWh-stock-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    min-height: fit-content;
    width: 100%;
}

.admWh-stock-filter-item {
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
    justify-content: center;
}

.admWh-stock-filter-item > p {
    color: var(--neutral-2nd-color);
    font-size: 0.875rem;
    margin: 0;
}

.admWh-stock-filter-dropdown-btn {
    align-items: center;
    background-color: var(--neutral-5th-color);
    border: 1px solid var(--neutral-3rd-color);
    border-radius: var(--b-rad-table);
    color: var(--neutral-2nd-color);
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;
    width: 72px;
}

.admWh-stock-filter-dropdown-btn > img {
    margin-top: -2.2px;
    margin-left: 4px;
    transition: 300ms;
}

.admWh-stock-filter-dropdown-menu {
    align-items: flex-start;
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-table);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    margin-top: 5px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: 300ms;
    width: 72px;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.admWh-stock-filter-dropdown-menu > li {
    align-items: center;
    color: var(--neutral-2nd-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;
}

.admWh-stock-filter-dropdown-selected {
    color: var(--primary-4th-color) !important;
    cursor: default !important;
}

/* ? EDIT STOCK MODAL CONTENT */
.edit-stock-modal-head {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.5rem;
    row-gap: 1rem;
}

.edit-stock-modal-head > h3 {
    text-align: center;
}

.edit-stock-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
}

.edit-stock-modal-foot {
    column-gap: 0.5rem;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

/* ? PAGINATION SECTION */
.admWh-stock-pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    min-height: 48px;
    padding: 0 1.5rem;
    width: 100%;
}

.admWh-stock-pagination-item {
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
    justify-content: center;
    margin: 1rem 0 0;
}

.admWh-stock-pagination-btn {
    background-color: var(--neutral-5th-color);
    border: none;
    border-radius: 100px;
    color: var(--neutral-2nd-color);
    cursor: pointer;
    height: 48px;
    width: 40px;
}

.admWh-stock-pagination-btn:hover {
    background-color: var(--primary-4th-color);
    color: var(--neutral-5th-color);
    transition: 400ms;
}

.admWh-stock-pagination-btn:disabled {
    background-color: var(--primary-4th-color);
    color: var(--neutral-5th-color);
    cursor: default;
}

.admWh-stock-firstPage-btn,
.admWh-stock-lastPage-btn,
.admWh-stock-prev-btn,
.admWh-stock-next-btn {
    background-color: var(--neutral-5th-color);
    border: none;
    border-radius: 100px;
    cursor: pointer;
    height: 48px;
    width: 88px;
}

.admWh-stock-firstPage-btn,
.admWh-stock-lastPage-btn {
    width: 48px;
}

.admWh-stock-firstPage-btn:hover > img,
.admWh-stock-prev-btn:hover > img,
.admWh-stock-next-btn:hover > img {
    transform: translateY(-2px);
    transition: 300ms;
}

.admWh-stock-lastPage-btn:hover > img {
    transform: translateY(-2px) rotate(180deg) !important; /* Klo ga important, ga fungsi */
    transition: 300ms;
}

.admWh-stock-firstPage-btn:disabled,
.admWh-stock-lastPage-btn:disabled,
.admWh-stock-prev-btn:disabled,
.admWh-stock-next-btn:disabled {
    background-color: var(--neutral-3rd-color);
    cursor: default;
    opacity: 50%;
}

.admWh-stock-lastPage-btn:disabled > img {
    transform: translateY(0px) rotate(180deg) !important; /* Utk counter selector yg diatas, jd pas disabled ga hover */
}

.admWh-stock-firstPage-btn:disabled:hover > img,
.admWh-stock-lastPage-btn:disabled:hover > img,
.admWh-stock-prev-btn:disabled:hover > img,
.admWh-stock-next-btn:disabled:hover > img {
    transform: translateY(0);
    transition: none;
}