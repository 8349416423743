:root {
    --primary-1st-color: #0A4D3C;
    --primary-2nd-color: #FCB537;
    --primary-3rd-color: #8D280C;
    --primary-4th-color: #B24629;
    --primary-5th-color: #F1EDE7;

    --neutral-1st-color: #070707;
    --neutral-2nd-color: #5A5A5A;
    --neutral-3rd-color: #CACACA;
    --neutral-4th-color: #F4F4F4;
    --neutral-5th-color: #FFFFFF;

    --semantic-error: #CB3A31;
    --semantic-warning: #EF8943;
    --semantic-success: #43936C;
    --semantic-info: #27A0E3;

    --b-rad-root: 12px;
    --b-rad-table: 8px;
    --b-shadow-root: 0px 1px 6px #D7D7D8;
}

/* ? UTILITY CLASS */
.link-no-decoration {
    color: inherit;
}

.link-no-decoration:hover {
    color: inherit;
    text-decoration: none;
}

.adm-404-main-wrap * {
    box-sizing: border-box;
}

.adm-404-sub-wrap h2,
.adm-404-sub-wrap h6 {
    margin: 0;
}

/* ? MAIN WRAPPER & CONTENTS SECTION */
.adm-404-main-wrap {
    align-items: center;
    background-color: var(--primary-5th-color);
    color: var(--neutral-1st-color);
    display: flex;
    height: 100vh;
    justify-content: center;
    min-height: 90vh;
    width: 100%;
}

/* ? SUB WRAPPER & CONTENTS SECTION */
.adm-404-sub-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    row-gap: 1.5rem;
    width: 100%;
}

/* ? IMG WRAPPER & CONTENTS SECTION */
.adm-404-img-wrap {
    height: 20rem;
    overflow: hidden;
    width: 20rem;
}

.adm-404-img-wrap > img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

/* ? BODY WRAPPER & CONTENTS SECTION */
.adm-404-body-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.adm-404-body-wrap > h2 {
    font-weight: 600;
    margin-bottom: 1rem;
}

.adm-404-body-wrap > h6 {
    color: var(--neutral-2nd-color);
}

.adm-404-body-wrap > h6:first-of-type {
    margin-bottom: 0.5rem;
}

/* ? FOOT WRAPPER & CONTENTS SECTION */
.adm-404-foot-wrap > button {
    margin-top: calc(40px - 1rem);
}