.log-req-ongoing {
  background-color: #fef0d7;
  border-radius: var(--b-rad-table);
  color: #ef8943;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.log-req-rejected {
  background-color: #f0dad4;
  border-radius: var(--b-rad-table);
  color: var(--semantic-error);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.log-req-accepted {
  background-color: #d0e4da;
  border-radius: var(--b-rad-table);
  color: var(--semantic-success);
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}
