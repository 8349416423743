:root {
    --primary-1st-color: #0A4D3C;
    --primary-2nd-color: #FCB537;
    --primary-3rd-color: #8D280C;
    --primary-4th-color: #B24629;
    --primary-5th-color: #F1EDE7;

    --neutral-1st-color: #070707;
    --neutral-2nd-color: #5A5A5A;
    --neutral-3rd-color: #CACACA;
    --neutral-4th-color: #F4F4F4;
    --neutral-5th-color: #FFFFFF;

    --semantic-error: #CB3A31;
    --semantic-warning: #EF8943;
    --semantic-success: #43936C;
    --semantic-info: #27A0E3;

    --b-rad-root: 12px;
    --b-rad-table: 8px;
    --b-shadow-root: 0px 1px 6px #D7D7D8;
}

/* ? GROUPED UTILITY CLASS FROM DIFFERENT SECTIONS */
.manage-adm-header-wrap > button,
.add-adm-modal-foot > button {
    background-color: var(--primary-4th-color);
    border: none;
    border-radius: 100px;
    color: var(--neutral-5th-color);
    font-weight: 600;
    height: 42px;
    width: 160px;
}

.manage-adm-header-wrap > button:hover,
.add-adm-modal-foot > button:hover {
    background-color: var(--primary-3rd-color);
    transition: 300ms;
}

/* ? MAIN WRAPPER SECTION */
.manage-adm-main-wrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1.5rem;
}

/* ? BREADCRUMB WRAP & CONTENTS SECTION */
.manage-adm-breadcrumb-wrap {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
}

.manage-adm-breadcrumb-wrap li { 
    /* Utk centering typography pd breadcrumb, karena lainnya pake Link dari react router dom */
    align-items: center;
    display: flex;
}

/* ? HEADER WRAP & CONTENTS SECTION */
.manage-adm-header-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.manage-adm-header-wrap > h4 {
    font-weight: 600;
}

/* ? CONTENTS/BODY WRAP & CONTENTS SECTION */
.manage-adm-contents-wrap {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-table);
    margin-top: 1.5rem;
    padding: 1.5rem;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

/* ? MANAGE ADMIN MODAL CONTENT */

/* * ADM MODAL HEAD WRAP & CONTENTS ON MANAGE ADM MODAL SECTION */
.add-adm-modal-head {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.5rem;
    row-gap: 1rem;
}

.add-adm-modal-head > h3 {
    margin: 0;
    text-align: center;
}

/* * ADM MODAL BODY WRAP & CONTENTS ON MANAGE ADM MODAL SECTION */
.add-adm-modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.add-adm-modal-body-dropdown {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.5rem;
    width: 100%;
}

.add-adm-modal-body > div:nth-of-type(2) {
    position: relative;
}

.add-adm-modal-body > div:nth-of-type(2) > img {
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 52%;
}

.add-adm-modal-body > div:nth-of-type(2) input[type=text] {
    /* Utk kasih jarak antar text dgn icon show password di posisi paling kanan elemen input saat type = text */
    padding-right: 3rem;
}

.add-adm-modal-body-dropdown > label {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
}

/* * ADM MODAL FOOT WRAP & CONTENTS ON MANAGE ADM MODAL SECTION */
.add-adm-modal-foot {
    column-gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.add-adm-modal-foot > button:first-of-type:disabled,
.add-adm-modal-foot > button:disabled {
    background-color: var(--neutral-3rd-color);
    border-color: var(--neutral-3rd-color);
    color: var(--neutral-5th-color);
    cursor: default;
}

.add-adm-modal-foot > button:first-child {
    background-color: var(--neutral-5th-color);
    border: 1px solid var(--primary-4th-color);
    color: var(--primary-4th-color);
}

.add-adm-modal-foot > button:first-child:hover {
    border-color: var(--primary-3rd-color);
    color: var(--primary-3rd-color);
    transition: 400ms;
}

/* ? DROPDOWN MENU SELECT WAREHOUSE ON ADD ADMIN MODAL */
.manage-adm-dropdown-wrap {
    position: relative;
    width: 100%;
}

.manage-adm-dropdown-btn {
    align-items: center;
    background-color: var(--neutral-5th-color);
    border: 1px solid var(--neutral-3rd-color);
    border-radius: var(--b-rad-table);
    color: var(--neutral-3rd-color);
    cursor: pointer;
    display: flex;
    height: 46px;
    justify-content: space-between;
    padding: 0.75rem;
    width: 100%;
}

.manage-adm-dropdown-btn > img {
    margin-right: 1.5px; /* Biar sejajar dgn icon showpass */
    margin-top: -2.2px;
    transition: 300ms;
}

.manage-adm-dropdown-menu {
    align-items: flex-start;
    background-color: var(--neutral-5th-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    margin-top: 0.5rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    transition: 300ms;
    width: 100%;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

.manage-adm-dropdown-menu > li {
    align-items: center;
    color: var(--neutral-2nd-color);
    cursor: pointer;
    display: flex;
    padding: 0.5rem;
    width: 100%;
}

.manage-adm-dropdown-menu > li:hover {
    color: var(--primary-4th-color);
    transition: 300ms;
}

.manage-adm-dropdown-selected {
    color: var(--primary-4th-color) !important;
    cursor: default !important;
}