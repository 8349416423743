.homepage-carousel {
  height: 300px;
  overflow: hidden;
}

.homepage-carousel-wrapper {
  position: relative;
}

.homepage-text-category {
  font-size: 20px;
  font-weight: 600;
}

.homepage-diskon {
  background-image: url("./../../../assets/banner-diskon.svg");
}

.homepage-carousel-diskon {
  width: 100%;
  overflow: hidden;
}

.homepage-carousel-wrapper-diskon {
  width: 140%;
  position: relative;
}

.homepage-footer-title {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}

.homepage-kategori {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.homepage-footer-content {
  font-size: 12px;
  color: #fff;
}

.homepage-carousel-product {
  position: relative;
}

@media only screen and (max-width: 480px) {
  .homepage-carousel {
    height: 170px;
  }

  .homepage-carousel {
    height: 170px;
  }

  .homepage-carousel-product {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 1024px) {
  .homepage-carousel-product.slick-arrow {
    display: none !important;
  }
}
