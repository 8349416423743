body {
  margin: 0;
  background-color: #9e6d22;
  font-family: "Poppins", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#document {
  height: 100px !important;
}

code {
  font-family: "Poppins", "Open Sans", sans-serif;
}
