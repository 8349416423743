.slide {
  transform: scale(1);
  transition: transform 500ms;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
}

.active-slide {
  transform: scale(1.1);
  opacity: 1;
  z-index: 1 !important;
}

.carousel-content {
  background-repeat: no-repeat;
  width: 548px !important;
  height: 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5% 0px;
  position: relative;
  border-radius: 12px;
  margin: 0 20px;
}

.carousel-slider {
  width: 1800px !important;
}

.carousel-testing {
  height: 250px !important;
}

.homepage-carousel-wrapper .carousel-dots {
  width: 24px;
  height: 4px;
  border-radius: 8px;
  background-color: #cacaca;
}

.slick-dots ul .slick-active {
  width: 48px;
  margin-right: 0 !important;
}

.slick-active .carousel-dots {
  width: 48px;
  height: 4px;
  border-radius: 8px;
  background-color: #b24629;
  transition: 0.2s;
  position: absolute;
}

#next,
#prev {
  position: absolute;
  top: 50%;
  z-index: 2;
}

#next {
  right: 32%;
}

#prev {
  left: 32%;
}

.carousel-btn {
  border: none;
  border-radius: 100%;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: #f4f4f4;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 480px) {
  .carousel-content {
    width: 272px !important;
    height: 106px;
  }

  .carousel-testing {
    height: 106px !important;
  }

  .carousel-slider {
    width: 1000px !important;
  }

  .homepage-carousel-wrapper .carousel-dots {
    margin-top: 1rem !important;
  }

  .carousel-cat-slider {
    width: 110vw !important;
  }
}

@media only screen and (max-width: 1024px) {
  .carousel-cat-slider {
    width: 120vw !important;
  }
}
