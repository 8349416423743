.adm-textbox-styling {
  border-radius: 12px;
  border: solid 1px #cacaca;
  padding: 12px;
  font-size: 1em;
}

.adm-textbox-styling:enabled {
  outline-color: #b24629;
}

.adm-textbox-styling::placeholder {
  color: #cacaca;
}

.adm-textbox-styling:focus {
  border-color: #b24629;
  outline: none;
}

.adm-textbox-error {
  border: solid 1px #c83532 !important;
  background-color: #fbefef !important;
}

.adm-textbox-error-msg {
  font-size: 0.75em;
  color: #cb3a31;
}

.adm-textbox-success-msg {
  font-size: 0.75em;
  color: #43936c;
}