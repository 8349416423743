.profile-container {
  /* min-height: 600px; */
  border-radius: 12px;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 32px;
  background-color: #fff;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.08);
}

.profile-col {
  padding: 0;
}

.profile-page-height {
  min-height: calc(100vh - 74px - 236px);
}

@media only screen and (max-width: 480px) {
  .profile-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
