:root {
    --primary-1st-color: #0A4D3C;
    --primary-2nd-color: #FCB537;
    --primary-3rd-color: #8D280C;
    --primary-4th-color: #B24629;
    --primary-5th-color: #F1EDE7;

    --neutral-1st-color: #070707;
    --neutral-2nd-color: #5A5A5A;
    --neutral-3rd-color: #CACACA;
    --neutral-4th-color: #F4F4F4;
    --neutral-5th-color: #FFFFFF;

    --semantic-error: #CB3A31;
    --semantic-warning: #EF8943;
    --semantic-success: #43936C;
    --semantic-info: #27A0E3;

    --b-rad-root: 12px;
    --b-rad-table: 8px;
    --b-shadow-root: 0px 1px 6px #D7D7D8;
}

/* ? GROUPED UTILITY CLASS FROM DIFFERENT SECTIONS */
.manage-wh-header-wrap button,
.create-wh-modal-foot > button {
    background-color: var(--primary-4th-color);
    border: none;
    border-radius: 100px;
    color: var(--neutral-5th-color);
    font-weight: 600;
    height: 42px;
    width: 208px;
}

.manage-wh-header-wrap button:hover,
.create-wh-modal-foot > button:hover {
    background-color: var(--primary-3rd-color);
    transition: 300ms;
}

/* ? MAIN WRAPPER SECTION */
.manage-wh-main-wrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1.5rem;
}

/* ? BREADCRUMB WRAP & CONTENTS SECTION */
.manage-wh-breadcrumb-wrap {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
}

.manage-wh-breadcrumb-wrap li { 
    /* Utk centering typography pd breadcrumb, karena lainnya pake Link dari react router dom */
    align-items: center;
    display: flex;
}

/* ? HEADER WRAP & CONTENTS SECTION */
.manage-wh-header-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.manage-wh-header-wrap > h4 {
    font-weight: 600;
}

/* ? CONTENTS/BODY WRAP & CONTENTS SECTION */
.manage-wh-contents-wrap {
    background-color: var(--neutral-5th-color);
    border-radius: var(--b-rad-table);
    margin-top: 1.5rem;
    padding: 1.5rem;

    box-shadow: var(--b-shadow-root);
    -webkit-box-shadow: var(--b-shadow-root);
    -moz-box-shadow: var(--b-shadow-root);
}

/* ? CREATE WH MODAL CONTENT */

/* * WH MODAL HEAD WRAP & CONTENTS ON WH MODAL SECTION */
.create-wh-modal-head {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2.5rem;
    row-gap: 1rem;
}

.create-wh-modal-head > h3 {
    margin: 0;
    text-align: center;
}

/* * WH MODAL BODY WRAP & CONTENTS ON WH MODAL SECTION */
.create-wh-modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.create-wh-modal-body > label:first-of-type, /* Klo ga gini, label first type nya ga mau keatur margin bottom nya */
.create-wh-modal-body > label {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: -0.5rem;
}

/* * WH MODAL FOOT WRAP & CONTENTS ON WH MODAL SECTION */
.create-wh-modal-foot {
    column-gap: 1rem;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.create-wh-modal-foot > button:first-child:disabled,
.create-wh-modal-foot > button:disabled {
    background-color: var(--neutral-3rd-color);
    border-color: var(--neutral-3rd-color);
    color: var(--neutral-5th-color);
    cursor: default;
}

.create-wh-modal-foot > button:first-child {
    background-color: var(--neutral-5th-color);
    border: 1px solid var(--primary-4th-color);
    color: var(--primary-4th-color);
}

.create-wh-modal-foot > button:first-child:hover {
    border-color: var(--primary-3rd-color);
    color: var(--primary-3rd-color);
    transition: 400ms;
}