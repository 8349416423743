.skeleton-cart-anim {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.skel-cart-img {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  background-color: hsl(200, 20%, 70%);
}

.skel-cart-name {
  width: 100%;
  height: 1rem;
  border-radius: 0.125rem;
  background-color: hsl(200, 20%, 70%);
}

.skel-cart-price {
  width: 40%;
  height: 1rem;
  border-radius: 0.125rem;
  background-color: hsl(200, 20%, 70%);
}

.skel-cart-hapus {
  width: 20%;
  height: 1rem;
  border-radius: 0.125rem;
  background-color: hsl(200, 20%, 70%);
}

.skel-cart-input {
  width: 40%;
  height: 2rem;
  border-radius: 0.125rem;
  background-color: hsl(200, 20%, 70%);
}
