.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  border-radius: 0.125rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.card-product-wrapper {
  width: 211px;
  height: 265px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 12px 0 rgba(161, 132, 87, 0.16);
  cursor: pointer;
}

.card-product-img {
  width: 100%;
  height: 119px;
  object-fit: cover;
}

.card-product-category {
  content: none;
  font-size: 0.625em;
  font-weight: 500;
  color: #0a4d3c;
}

.card-product-name {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.63;
  color: #5a5a5a;
}

.card-product-diskon {
  font-size: 0.625em;
  color: #cacaca;
}

.card-product-price {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.63;
  color: #070707;
}

@media only screen and (max-width: 480px) {
  .card-product-wrapper {
    width: 132px;
    height: 209px;
  }

  .card-product-img {
    width: 100%;
    height: 75px;
    object-fit: cover;
  }

  .card-product-category {
    font-size: 0.5em;
  }

  .card-product-name {
    font-size: 0.625em;
  }
}
