.checkout-col {
  padding: 0;
}

.checkout-wrapper {
  border-radius: 12px;
  box-shadow: 0 1px 12px 0 rgba(161, 132, 87, 0.16);
  background-color: #fff;
  overflow: hidden;
}

.checkout-title {
  font-size: 1em;
  font-weight: 600;
  color: #0a4d3c;
}

.checkout-datalist {
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
  font-size: 0.875em;
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

.checkout-listadd-wrapper {
  height: 300px;
  overflow: auto;
  font-size: 0.75em;
}

.checkout-btn-address {
  border: none;
  background-color: transparent;
  color: #b24629;
  font-size: 0.875em;
}

.checkout-label-input {
  font-size: 0.875em;
  font-weight: 500;
}

.checkout-input-address {
  font-size: 0.875em;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
}

.checkout-input-address:enabled {
  outline-color: #b24629;
}

.checkout-mainadd {
  font-size: 0.75em;
  font-weight: 500;
  color: #070707;
}

.checkout-address {
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
}

.checkout-recipient {
  font-size: 0.75em;
  font-weight: 500;
  color: #0a4d3c;
}

.checkout-detail-add {
  font-size: 0.625em;
  font-weight: 500;
  color: #5a5a5a;
}

.checkout-chooseadd {
  border: none;
  background-color: transparent;
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
}

.checkout-img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
}

.checkout-priceprod {
  font-size: 0.625em;
  font-weight: 500;
  color: #0a4d3c;
}

.checkout-nameprod {
  font-size: 0.875em;
  font-weight: 600;
  color: #5a5a5a;
}

.checkout-border {
  border-bottom: 1px solid #f4f4f4;
}

.checkout-total {
  font-size: 0.875em;
  font-weight: 600;
  color: #070707;
}

.checkout-kurir {
  border-radius: 8px;
  border: solid 1px #cacaca;
  background-color: #fff;
}

.checkout-kurir-active {
  border: solid 1px #b24629 !important;
}

.checkout-pricekurir {
  font-size: 0.75em;
  font-weight: 600;
  color: #000;
  max-width: 25%;
  min-width: 25%;
}

.checkout-jeniskurir {
  font-size: 0.75em;
  font-weight: 500;
  color: #070707;
}

.checkout-desckurir {
  font-size: 0.75em;
  font-weight: 500;
  color: #5a5a5a;
}

.checkout-bank-wrapper {
  border-radius: 8px;
  border: solid 1px #f4f4f4;
}

.checkout-bank-img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.checkout-list-bank {
  font-size: 0.75em;
  font-weight: 500;
  color: #5a5a5a;
}

.checkout-bank-active {
  color: #b24629 !important;
}

.checkout-border-bank {
  border-bottom: solid 1px #f4f4f4;
}

.checkout-prod-payment {
  font-size: 0.625em;
  color: #5a5a5a;
}

.checkout-payment-total {
  font-size: 0.75em;
  font-weight: 500;
  color: #5a5a5a;
}

.checkout-grand-total {
  font-size: 0.875em;
  font-weight: 600;
  color: #070707;
  background-color: #f4f4f4;
}

textarea[name="address"] {
  resize: none;
}

.checkout-address-note {
  font-size: 0.75em;
  font-weight: 400;
  color: #b24629;
}

.checkout-close-modal {
  border: none;
  background-color: transparent;
}

.checkout-modal {
  width: 40% !important;
  aspect-ratio: 1;
  overflow: auto;
}

.checkout-address-btn {
  background: #ffffff;
  border: 1px solid #b24629;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 0.875em;
  color: #b24629;
  font-weight: 600;
}

.checkout-list-address {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
}

.checkout-address-main {
  background: #f4f4f4;
  border-radius: 8px;
  padding: 4px 8px;
}

.checkout-address-action {
  background: #f4f4f4;
  border-radius: 0px 0px 7px 7px;
}

.checkout-addressaction-border {
  border-left: 1px solid #cacaca;
}

.checkout-ubah-btn {
  border: none;
  background-color: transparent;
}

.checkout-btn-batal {
  border: 1px solid #b24629;
  background-color: transparent;
  border-radius: 100px;
  font-size: 0.875em;
  font-weight: 600;
  color: #b24629;
  padding: 8px 10px;
}

.checkout-btn-pilihalamat {
  border: none;
  background-color: #b24629;
  position: sticky;
  bottom: 0;
  transition: all 0.3s;
}

.checkout-btn-pilihalamat:hover {
  background-color: #8d280c;
}

.checkout-btn-pilihalamat-loading {
  position: sticky;
  bottom: 0;
  border: none;
  background-color: #cacaca;
}

@media only screen and (max-width: 480px) {
  .checkout-btn-wrap-resp {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .checkout-btn-resp {
    width: 75% !important;
  }

  .checkout-modal {
    width: 100% !important;
    height: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .checkout-container-resp {
    font-size: 12px !important;
  }

  .checkout-modal {
    width: 75% !important;
    height: 75%;
  }
}
