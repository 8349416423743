.skeleton-card-wrapper {
  width: 211px;
  height: 265px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 12px 0 rgba(161, 132, 87, 0.16);
}

.skeleton-card-img {
  width: 100%;
  height: 119px;
  background-color: hsl(200, 20%, 70%);
  opacity: 0.7;
}

.skeleton-card-category {
  width: 45%;
  height: 0.875rem;
  background-color: hsl(200, 20%, 70%);
  border-radius: 0.15rem;
  opacity: 0.7;
}

.skeleton-card-title {
  --w: 100%;
  --h: 1.25rem;
  width: var(--w);
  height: var(--h);
  background-color: hsl(200, 20%, 70%);
  border-radius: 0.15rem;
  opacity: 0.7;
}

.skeleton-card-title-sm {
  --w: 50%;
}

.skeleton-card-price {
  width: 40%;
  height: 1rem;
  background-color: hsl(200, 20%, 70%);
  border-radius: 0.15rem;
  opacity: 0.7;
}

.skeleton-card-anim {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .skeleton-card-wrapper {
    width: 132px;
    height: 209px;
  }

  .skeleton-card-img {
    width: 100%;
    height: 75px;
  }
}
